<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Fijación de precios</h1>
      <p>
        No somos un negocio extractivo sino que intentamos que todo lo que hacemos sea regenerativo para
        mejorar la tierra desde la que operamos.
      </p>
      <p>
        Nuestros clientes aprecian el valor de un producto o servicio al entender completamente de dónde viene y cómo
        está hecho. Cuanto más sepamos todos, mejor podremos tomar decisiones.
      </p>
      <p>
        No hay un precio fijo para ningún producto o servicio. Como cliente, puede mover un control deslizante de
        precios y establecer el precio suyo. Creemos en tu juicio. Hay un precio sugerido y puedes bajar o
        aumentar el precio que estás dispuesto a pagar. Cuanto más aumente el precio, más podremos invertir en la parte
        regenerativa de nuestra operación. Si bajas el precio, entendemos que por ahora es lo que te puedes permitir.
      </p>

      <YouTubeLink />
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "PriceSliderES",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
